<div class="grid overflow-hidden">
  <div class="col-12 p-0 m-0">
    <p-toolbar class="p-0 m-0" styleClass="bg-white">
      <div class="bg-white">
        <!--<p-image src="assets/images/tpe.png" alt="Logo Tpe" height="40" (click)="goToHome()"></p-image>-->
        <h1 class="flex justify-content-start font-bold text-blue-900 ml-4 mb-0">TPE SJC</h1>
      </div>
      <div class="mt-2">
        <p-megaMenu class="sm:flex" styleClass="sm:inline-flex" [model]="menus"></p-megaMenu>
      </div>
      <div class="mr-4 p-2 pb-0 p-0 m-0 bg-white">
        <p-avatar  styleClass="mr-2 bg-blue-900"  [routerLink]="['/perfil-publicador']" [style]="{ 'color': '#ffffff', 'cursor': 'pointer'}" shape="circle">
          <label class=" text-2xl pt-2" [style]="{'cursor': 'pointer'}">{{ nomeUsuario }}</label>
        </p-avatar>
        <a class="menu-logout" (click)="logout()">
          <i class="pi pi-sign-out mr-2"></i>
          <span class="text-900">Sair</span>
        </a>
      </div>
    </p-toolbar>
  </div>  
  <div class="col-12  bg-white">
    <section>
      <router-outlet></router-outlet>
    </section>
  </div>
</div>
