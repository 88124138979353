// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,

    // Configurações Aplicação:
    serverUrl: 'https://api.tpesjc.com.br/api/',
    baseUrl: 'https://api.tpesjc.com.br/',
    //serverUrl: 'http://localhost:8882/api/',
    //baseUrl: 'http://localhost:8882/api/',
    //clientUrl: 'http://localhost:4200/',
    obterTokenUrl: 'publicador/auth',
    esquecisenha: 'publicador/esquecisenha'

    // API's
    //urlSufApi: 'http://localhost:3000/'
}

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
